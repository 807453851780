import whenReady from '../../../javascripts/utils/when-ready';

whenReady().then(() => {
  const $$teasersSlider = document.querySelectorAll('.teasers-slider');
  $$teasersSlider.forEach(($teasersSlider) => {
    // Get navigation buttons
    const $leftButton = $teasersSlider.querySelector(
      '.teasers-slider__button--left .icon-button',
    );
    const $rightButton = $teasersSlider.querySelector(
      '.teasers-slider__button--right .icon-button',
    );

    // Get scroll container
    const $scroller = $teasersSlider.querySelector('.teasers-slider__scroller');

    // Get second item with margin
    const $secondItem = $teasersSlider.querySelector(
      '.teasers-slider__teaser:nth-child(2)',
    );

    // Toggle navigation buttons
    const toggleNavigationButtons = () => {
      const hideThreshold = 50;
      const { offsetWidth, scrollWidth, scrollLeft } = $scroller;
      $leftButton.disabled = scrollLeft < hideThreshold;
      $rightButton.disabled =
        scrollLeft > scrollWidth - offsetWidth - hideThreshold;
    };

    // Update navigation buttons on scroll and init
    $scroller.addEventListener('scroll', toggleNavigationButtons, {
      passive: true,
    });
    toggleNavigationButtons();

    // Move scroll container on click
    const clickHandler = (direction) => (event) => {
      event.preventDefault();

      const { width, marginLeft } =
        $secondItem.currentStyle || window.getComputedStyle($secondItem);

      $scroller.scrollLeft +=
        (parseInt(width, 10) + parseInt(marginLeft, 10)) * direction;
    };

    $leftButton.addEventListener('click', clickHandler(-1));
    $rightButton.addEventListener('click', clickHandler(1));
  });
});
