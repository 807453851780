import { createFocusTrap } from 'focus-trap';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { on } from 'delegated-events';
import whenReady from '../../../javascripts/utils/when-ready';

let focusTrap = null;

on('click', '.header__menu-button', (event) => {
  const $menuButton = event.target.closest('.header__menu-button');
  const $header = $menuButton.closest('.header');
  const $menu = $header.querySelector('.header__overlay');
  const isOpen = $menuButton.getAttribute('aria-expanded') !== 'true';

  // Update state
  $menuButton.setAttribute('aria-expanded', isOpen ? 'true' : 'false');
  $header.classList.toggle('header--open', isOpen);
  $menu.hidden = !isOpen;

  // On open
  if (isOpen) {
    focusTrap = createFocusTrap($header, {
      onDeactivate() {
        $menuButton.click();
      },
      initialFocus: '.header__overlay-inner',
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: true,
      preventScroll: true,
    });

    disableBodyScroll($header);
    focusTrap.activate();
  } else {
    if (focusTrap) {
      focusTrap.deactivate({
        onDeactivate: false,
      });
      focusTrap = null;
    }

    enableBodyScroll($header);
  }
});

whenReady().then(() => {
  const $header = document.querySelector('.header');

  if ($header) {
    // On init
    $header.classList.toggle('header--scrolled', window.scrollY > 50);

    // On scroll
    document.addEventListener(
      'scroll',
      () => {
        $header.classList.toggle('header--scrolled', window.scrollY > 50);
      },
      { passive: true },
    );
  }
});
