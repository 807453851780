import { on } from 'delegated-events';

function stripParameters(str) {
  // Split parameters or split folder separator
  if (str.indexOf('?') > -1) {
    return str.split('?')[0];
  }

  if (str.indexOf('/') > -1) {
    return str.split('/')[0];
  }

  if (str.indexOf('&') > -1) {
    return str.split('&')[0];
  }

  return str;
}

function youtube(str) {
  // remove time hash at the end of the string
  // eslint-disable-next-line no-param-reassign
  str = str.replace(/#t=.*$/, '');

  // shortcode
  const shortcode = /youtube:\/\/|https?:\/\/youtu\.be\/|http:\/\/y2u\.be\//g;

  if (shortcode.test(str)) {
    const shortcodeid = str.split(shortcode)[1];
    return stripParameters(shortcodeid);
  }

  // /v/ or /vi/
  const inlinev = /\/v\/|\/vi\//g;

  if (inlinev.test(str)) {
    const inlineid = str.split(inlinev)[1];
    return stripParameters(inlineid);
  }

  // v= or vi=
  const parameterv = /v=|vi=/g;

  if (parameterv.test(str)) {
    const arr = str.split(parameterv);
    return stripParameters(arr[1].split('&')[0]);
  }

  // v= or vi=
  const parameterwebp = /\/an_webp\//g;

  if (parameterwebp.test(str)) {
    const webp = str.split(parameterwebp)[1];
    return stripParameters(webp);
  }

  // embed
  const embedreg = /\/embed\//g;

  if (embedreg.test(str)) {
    const embedid = str.split(embedreg)[1];
    return stripParameters(embedid);
  }

  // ignore /user/username pattern
  const usernamereg = /\/user\/([a-zA-Z0-9]*)$/g;

  if (usernamereg.test(str)) {
    return undefined;
  }

  // user
  const userreg = /\/user\/(?!.*videos)/g;

  if (userreg.test(str)) {
    const elements = str.split('/');
    return stripParameters(elements.pop());
  }

  // attribution_link
  const attrreg = /\/attribution_link\?.*v%3D([^%&]*)(%26|&|$)/;

  if (attrreg.test(str)) {
    return stripParameters(str.match(attrreg)[1]);
  }

  return null;
}

on('click', '.js-video', (event) => {
  event.preventDefault();

  const $target = event.target.closest('.js-video');
  const id = youtube($target.href);

  if (id) {
    // Create wrapper
    const $wrapper = document.createElement('div');
    $wrapper.className = 'video__player-wrapper';

    // Get timecode
    const [, timecode = null] = $target.href.match(/(?:&|\?)t=(\d+)/) || [];

    // Create iFrame
    const $iframe = document.createElement('iframe');
    $iframe.className = 'video__player';
    $iframe.src = `https://www.youtube-nocookie.com/embed/${id}?rel=0&autoplay=1${
      timecode ? `&start=${timecode}` : ''
    }`;
    $iframe.allowFullscreen = true;
    $wrapper.appendChild($iframe);

    // Replace with iframe
    $target.parentNode.replaceChild($wrapper, $target);
  }
});
